import { GET_PURCHASE_COIN_HISTORY, GET_VIP_PLAN_HISTORY } from "./types";

const initialState = {
  history: [],
  vipplanHistory : []
};

const purchaseCoinHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_COIN_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
      case GET_VIP_PLAN_HISTORY:
      return {
        ...state,
        vipplanHistory: action.payload,
      };

    default:
      return state;
  }
};

export default purchaseCoinHistoryReducer;
